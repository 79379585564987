<template>
  <Card shadow="never" :body-style="{ border: '0', height: '100%' }">
    <template v-slot:header>{{ $t('0165560') }}</template>
    <CountryTag @select="handleSelect" />
    <div class="list-container">
      <div v-for="(item, index) in data" :key="index" class="list-item" @click="openUrl(item.url)">
        <div class="title">{{ item.title }}</div>
        <div class="date">
          <span>{{ getDate(item.issueDate) | date("MM-DD") }}</span>
          <span class="sub">{{ getDate(item.issueDate) | date("YYYY") }}</span>
        </div>
      </div>
    </div>
    <Pagination :page-info="pageInfo" @changePage="changePage" />
  </Card>
</template>

<script>
import Pagination from "@/components/Pagination"
import Card from "@/components/Card"

import { CONSULTING_TYPE } from "@/utils/constants"

import sendImage from "@/assets/content/send.png"
import viewImage from "@/assets/content/view.png"

import api from "@/api/information.policy"

import CountryTag from "./country"

import { mapGetters, mapActions } from "vuex"

export default {
  name: "RequestManage",
  components: {
    Pagination,
    Card,
    CountryTag,
  },
  data() {
    return {
      CONSULTING_TYPE,
      viewImage,
      sendImage,
      countryId: '',
      data: [],
      pageInfo: {
        currentPage: 1,
        size: 10,
        total: 0,
      },
    }
  },
  computed: {
    ...mapGetters(["dictionaries"]),
  },
  mounted() {
    this.getList()
  },
  methods: {
    ...mapActions(["getAreaTree", "getDictionary"]),
    handleSelect(id) {
      this.countryId = id
      this.pageInfo.currentPage = 1
      this.getList({ country: id })
    },
    openUrl(url) {
      window.open(url)
    },
    getDate(dateStr) {
      return dateStr ? dateStr.split('T')[0] : null
    },
    getYear(dateStr) {
      const date = new Date(dateStr)
      return date.getFullYear()
    },
    cellClassName({ column }) {
      if (column.property === "title") {
        return "table-title"
      }
      return ""
    },
    changePage(page) {
      this.pageInfo.currentPage = page
      this.getList({ country: this.countryId })
    },
    handleRowClick(row, column, cell) {
      // console.log(row, column, cell);
      if (column.property === "title") {
        this.$router.push({
          path: `/business/detail?id=${row.id}`,
        })
      }
    },
    getList(params = {}) {
      api
        .get({
          size: this.pageInfo.size,
          page: this.pageInfo.currentPage,
          ...params,
        })
        .then((res) => {
          if (res) {
            this.data = res.content
            this.pageInfo.total = res.page.total
          }
        })
    },
  },
}
</script>
<style scoped lang="less">
.list-container {
  padding: 0 10px;
  margin-bottom: 16px;
  .list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 29px 0;
    color: #333333;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.09);
    cursor: pointer;
    .title {
      font-size: 16px;
      font-family: MicrosoftYaHei;

      line-height: 28px;
    }
    .date {
      margin-left: 10px;
      text-align: right;
      font-size: 20px;
      color: #666666;
      line-height: 28px;
      width: 100px;
      span {
        display: block;
      }
      span.sub {
        font-size: 14px;
        line-height: 14px;
        margin-top: 10px;
      }
    }
  }
  .list-item:hover {
    color: rgba(0, 164, 255, 1);
    .date span {
      color: rgba(0, 164, 255, 1);
    }
  }
}
</style>
