<template>
  <div class="tag-container">
    {{ $t('47fef72') }}：
    <span class="tag-list">
      <span
        :class="`tag ${selected === '' ? 'active' : ''}`"
        @click="handleTagClick('')"
      >
        全部
      </span>
      <span
        v-for="item in countries"
        :key="item.id"
        :class="`tag ${selected === item.name ? 'active' : ''}`"
        @click="handleTagClick(item.name)"
      >
        {{ item.name }}
      </span>
    </span>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
export default {
  name: "CountryTag",
  data() {
    return {
      countryList: [],
      selected: "",
    }
  },
  computed: {
    ...mapGetters(["dictionaries"]),
    countries() {
      const list = this.dictionaries['COUNTRY'] ? this.dictionaries['COUNTRY'].list : []
      return list.filter(item => {
        return item.id !== 1
      })
    }
  },
  methods: {
    ...mapActions(["getDictionary"]),
    handleTagClick(id) {
      this.selected = id
      this.$emit("select", id)
    },
  },
  updated() {
    console.log(this.dictionaries["COUNTRY"])
  },
  mounted() {
    this.getDictionary({ type: "COUNTRY" })
  },
}
</script>

<style scoped lang="less">
.tag-container {
  margin-left: 10px;
  padding: 10px 0 30px 0;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.09);
}

.tag-list {
  .tag {
    display: inline-block;
    padding: 4px 8px;
    margin: 5px 3px;
    border-radius: 4px;
    cursor: pointer;
    height: 20px;
    line-height: 18px;
  }
  .tag:hover {
    background: rgba(24, 144, 255, 0.07);
  }
  .tag.active {
    background: rgba(24, 144, 255, 1);
    color: white;
  }
}
</style>
